import { Skeleton } from "antd";
import React, { FC, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import BookingList from "../../domain/Booking/BookingPages/BookingList";
import BookingView from "../../domain/Booking/BookingPages/BookingView";
import Categories from "../../domain/Categories/Categories";
import Cities from "../../domain/Cities/Cities";
import CityView from "../../domain/Cities/CityView";
import DashBoard from "../../domain/Dashboard/DashBoard";
import HotelList from "../../domain/Hotels/Hotels";
import HotelView from "../../domain/Hotels/HotelView";
import PackagesList from "../../domain/Packages/PackagesList";
import PackageView from "../../domain/Packages/PackageView";
import ProviderList from "../../domain/Providers/ProviderPages/ProviderList";
import ProviderView from "../../domain/Providers/ProviderPages/ProviderView";
import UserList from "../../domain/Users/UserPages/UserList";
import UserView from "../../domain/Users/UserPages/UserView";
import CategoryView from "../../domain/Categories/Category";
import PackageListFrontEnd from "../../domain/FrontEnd/PackageList/PackageList";
import PackageViewFrontEnd from "../../domain/FrontEnd/PackageView/PackageView";
import PackageBookingFrontEnd from "../../domain/FrontEnd/PackageBooking/PackageBooking";
import HotelPageFrontEnd from "../../domain/FrontEnd/HotelPage/HotelPage";
import ThingsToDoPageFrontEnd from "../../domain/FrontEnd/ThingsToDoPage/ThingsToDoPage";
import HomePageFrontEnd from "../../domain/FrontEnd/HomePage/HomePage";
import BookingConfirmPageFrontEnd from "../../domain/FrontEnd/PackageBooking/PackageBookingConfirmation";
import BookingErrorPageFrontEnd from "../../domain/FrontEnd/PackageBooking/PackageBookingError";
import TermsPageFrontEnd from "../../domain/FrontEnd/TermsPage/TermsPage";
import LogIn from "../../domain/Auth/LogIn";
import PackagesListPasydy from "../../domain/Packages/PackagesListPasydy";
import PackageListPasydyFrontEnd from "../../domain/FrontEnd/PackageList/PackageListPasydy";
import CruisePage from "../../domain/FrontEnd/CruisePage/CruisePage";
import { PrivateRoute } from "./PrivateRoute";

interface IClickableProps {
  childProps: string;
}

const AppRouter: FC<IClickableProps> = ({ childProps }): JSX.Element => (
  <Suspense fallback={<Skeleton paragraph active loading />}>
    <Switch>
      <Redirect exact from="/projects" to="/projects/active" />
      <Redirect exact from="/project/:id" to="/project/:id/details" />
      <Redirect exact from="/tickets" to="/tickets/assigned-to-me" />
      <Route path="/login" component={LogIn} />
      <Route exact path="/" component={HomePageFrontEnd} />
      <Route exact path="/packages" component={HomePageFrontEnd} />
      <Route exact path="/cruises" component={CruisePage} />
      <Route path="/packages/:params" component={PackageListFrontEnd} />
      <Route
        path="/pasydy-packages/:params"
        component={PackageListPasydyFrontEnd}
      />
      <Route path="/package/:id" component={PackageViewFrontEnd} />
      <Route path="/booking/:id" component={PackageBookingFrontEnd} />
      <Route
        path="/booking-confirm/:id"
        component={BookingConfirmPageFrontEnd}
      />
      <Route
        path="/booking-error/:id/:params"
        component={BookingErrorPageFrontEnd}
      />
      <Route path="/hotels" component={HotelPageFrontEnd} />
      <Route path="/things-to-do" component={ThingsToDoPageFrontEnd} />
      <Route path="/terms" component={TermsPageFrontEnd} />
      <PrivateRoute path="/admin/bookings" component={BookingList} />
      <PrivateRoute path="/admin/booking/:id" component={BookingView} />
      <PrivateRoute path="/admin/cities" component={Cities} />
      <PrivateRoute path="/admin/city/:id" component={CityView} />
      <PrivateRoute path="/admin/categories" component={Categories} />
      <PrivateRoute path="/admin/category/:id" component={CategoryView} />
      <PrivateRoute path="/admin/" exact component={DashBoard} />
      <PrivateRoute path="/admin/hotels" component={HotelList} />
      <PrivateRoute path="/admin/hotel/:id" component={HotelView} />
      <PrivateRoute path="/admin/packages" component={PackagesList} />
      <PrivateRoute
        path="/admin/packages-pasydy"
        component={PackagesListPasydy}
      />
      <PrivateRoute path="/admin/package/:id" component={PackageView} />
      <PrivateRoute path="/admin/providers" component={ProviderList} />
      <PrivateRoute path="/admin/provider/:id" component={ProviderView} />
      <PrivateRoute path="/admin/users" component={UserList} />
      <PrivateRoute path="/admin/user/:id" component={UserView} />
      {/* <Route
        path="/auth"
        component={AuthPage}

      /> */}
      {/* <AuthenticatedRoute
        exact
        path="/"
        component={AsyncMainDashboard}
        props={childProps}
      /> */}
    </Switch>
  </Suspense>
);

export default AppRouter;
