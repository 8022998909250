/* eslint-disable no-console */
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Many, flatten, map, uniq } from "lodash";
import classNames from "classnames";
import { useMediaQuery } from "react-responsive";
import { userFirestoreCollectionOnce } from "../../../App/hooks/userFirestoreCollection";
import {
  fetchCategoriesFromFirestore,
  fetchCitiesFromFirestore,
  fetchHotelsFromFirestore,
  fetchPackagesFromFirestore,
} from "../../../App/firestore/firestoreService";
import { listenToPackages } from "../../../App/redux/data/package/packageActions";
import {
  ICategory,
  ICity,
  IPackage,
  IQueryConfigArgs,
} from "../../../App/common/typings";
import { ItemNotFound, Loader } from "../../molecules";
import { listenToCities } from "../../../App/redux/data/city/cityActions";
import { listenToCategories } from "../../../App/redux/data/category/categoryActions";
import { listenToHotels } from "../../../App/redux/data/hotel/hotelActions";
import PackageFilter from "./PackageFilter";
import { PackageListFrontEndWrapper } from "./PackageListFrontEnd.style";
import { PackageItemList } from "./PackageItemList";

const defaultSorting: IQueryConfigArgs = {
  type: "sorting",
  name: "minPricePerAdult",
  order: "asc",
  opr: "asc",
};

export const PackageListFrontEnd: React.FC<{
  params: any;
  partner?: string;
}> = ({ params, partner }) => {
  const { packages } = useSelector((state: any) => state.packages);
  const { cities } = useSelector((state: any) => state.cities);
  const { categories } = useSelector((state: any) => state.categories);

  const { hotels } = useSelector((state: any) => state.hotels);
  const { loading, error } = useSelector((state: any) => state.async);
  const [showFilter, setShowFilters] = useState(true);
  const [packageCategoriesCode, setPackageCategoriesCode] = useState<
    ICategory[]
  >([]);
  const [packageCites, setPackageCites] = useState<ICity[]>([]);
  const [sorting, setSorting] = useState<IQueryConfigArgs>(defaultSorting);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1200px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });
  const dispatch = useDispatch();
  userFirestoreCollectionOnce({
    query: () =>
      fetchPackagesFromFirestore([
        {
          type: "filter",
          name: "status",
          opr: "==",
          value: true,
        },
        (partner && {
          type: "filter",
          name: "partnerId",
          opr: "==",
          value: partner,
        }) ||
          null,
        // ...convertParamsToQueryConfig(params),
      ]),
    data: (data: any) =>
      partner
        ? listenToPackages(data)
        : listenToPackages(
            data.filter((p: IPackage) => p.onlyPartner !== true)
          ),
    deps: [dispatch],
  });
  userFirestoreCollectionOnce({
    query: () =>
      fetchCitiesFromFirestore([
        {
          type: "filter",
          name: "showFrontEndFilter",
          opr: "==",
          value: true,
        },
      ]),
    data: listenToCities,
    deps: [dispatch],
  });
  userFirestoreCollectionOnce({
    query: () =>
      fetchCategoriesFromFirestore([
        {
          type: "sorting",
          name: "name",
          opr: "asc",
          order: "asc",
        },
      ]),
    data: listenToCategories,
    deps: [dispatch],
  });
  userFirestoreCollectionOnce({
    query: () =>
      fetchHotelsFromFirestore([
        {
          type: "sorting",
          name: "name",
          opr: "asc",
          order: "asc",
        },
        {
          type: "filter",
          name: "topHotel",
          opr: "==",
          value: true,
        },
      ]),
    data: listenToHotels,
    deps: [dispatch],
  });
  const handelShowFilter = () => {
    setShowFilters((v) => !v);
  };
  const handelSortingChange = (value: string) => {
    const [name, direction]: [string, Many<boolean | "asc" | "desc">] =
      Object.values(value.split("-")) as [
        string,
        Many<boolean | "asc" | "desc">
      ];
    setSorting({ type: "sorting", name, opr: "", order: direction });
  };
  React.useEffect(() => {
    if (!isTabletOrMobile) {
      setShowFilters(false);
    } else {
      setShowFilters(true);
    }
  }, [isTabletOrMobile]);
  React.useEffect(() => {
    if (packages) {
      const packCat: string[] =
        uniq(flatten(map(packages, (item) => flatten(item.categoryCodes)))) ||
        [];
      const packCities: string[] =
        uniq(
          flatten(map(packages, (item) => flatten(item.destinationCodes)))
        ) || [];
      const selectCat = categories.filter((c: any) => packCat.includes(c.code));
      const selectCities = cities.filter((c: any) =>
        packCities.includes(c.code)
      );
      setPackageCites(selectCities);
      setPackageCategoriesCode(selectCat);
    }
  }, [packages, categories, cities]);
  if (loading) return <Loader color="success" title="Loading" />;
  if (error) {
    return (
      <ItemNotFound
        redirectTo="/"
        redirectBtnText="Back to Home Page"
        itemType="City"
        itemId={"sd"}
      />
    );
  }
  // console.log(packages);
  return (
    <PackageListFrontEndWrapper>
      <div className="package-list-frontend">
        <div className="package-list-frontend-body">
          <div
            className={classNames("package-list-frontend-left", {
              hide: showFilter,
            })}
          >
            <PackageFilter
              cities={packageCites}
              categories={packageCategoriesCode}
              isTabletOrMobile={isTabletOrMobile}
              showFilter={handelShowFilter}
              params={params}
              hotels={hotels}
              url={partner ? "pasydy-packages" : "packages"}
            />
          </div>

          <PackageItemList
            travelPackages={packages}
            sorting={sorting}
            isTabletOrMobile={isTabletOrMobile}
            isMobile={isMobile}
            onSort={handelSortingChange}
            onShowFilter={handelShowFilter}
            partner={partner}
          />
        </div>
      </div>
    </PackageListFrontEndWrapper>
  );
};
