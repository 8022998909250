/* eslint-disable no-console */
import React from "react";
import { useHistory } from "react-router-dom";
import { IPackage } from "../../../App/common/typings";
import { ImageGallery, PackageAction, PackageInfo } from "../../molecules";
import { PackageItemWrapper } from "./PackageListFrontEnd.style";
export interface IPackageItemProps {
  travelPackage: IPackage;
  isMobile: boolean;
  partner?: string;
  discount?: number;
}

const PackageItem: React.FC<IPackageItemProps> = ({
  travelPackage,
  isMobile,
  partner,
  discount,
}) => {
  const history = useHistory();
  const handelSelect = () => {
    if (isMobile) {
      history.push(`/package/${travelPackage?.id}`);
    } else {
      const win: any = window.open(`/package/${travelPackage?.id}`, "_blank");
      win.focus();
    }
    // history.push(`/package/${travelPackage.id}`);
  };
  return (
    <PackageItemWrapper>
      <div>
        <ImageGallery
          images={travelPackage?.images || []}
          showThumbnails={false}
          showPlayButton={false}
        />
      </div>
      <div>
        <PackageInfo
          title={travelPackage?.name || ""}
          departureCity={travelPackage?.departureCity || ""}
          departureDate={travelPackage?.departureDate || ""}
          returnDate={travelPackage?.returnDate || ""}
          serviceInclude={travelPackage?.serviceInclude || []}
          categories={travelPackage?.categories || []}
        />
      </div>
      <div>
        <PackageAction
          price={travelPackage?.minPricePerAdult || 0}
          currency="€"
          onSelect={handelSelect}
          discount={discount}
        />
      </div>
    </PackageItemWrapper>
  );
};

export default PackageItem;
