import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { dataFromSnapshot } from "../firestore/firestoreService";
import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError,
} from "../redux/data/async/asyncReducer";

export interface IUserFirestoreCollection {
  query?: string;
  data?: (data: any) => void;
  deps?: any[];
}
export const userFirestoreCollection: any = ({
  query,
  data,
  deps,
  shouldExecute = true,
  local,
}: any) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!shouldExecute) return;
    if (!query()) return;
    !local && dispatch(asyncActionStart());
    const unsubscribe = query().onSnapshot(
      (snapshot: any) => {
        const docs = snapshot.docs.map((doc: any) => dataFromSnapshot(doc));
        local ? data(docs) : dispatch(data(docs));
        !local && dispatch(asyncActionFinish());
      },
      (error: any) => (local ? error : dispatch(asyncActionError(error)))
    );
    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  }, deps);
};

export const userFirestoreCollectionOnce: any = ({
  query,
  data,
  deps,
  shouldExecute = true,
  local,
}: any) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      if (!shouldExecute) return;
      if (!query()) return;
      !local && dispatch(asyncActionStart());

      try {
        const snapshot = await query().get();
        const docs = snapshot.docs.map((doc: any) => dataFromSnapshot(doc));
        local ? data(docs) : dispatch(data(docs));
        !local && dispatch(asyncActionFinish());
      } catch (error) {
        if (!local) dispatch(asyncActionError(error));
        // eslint-disable-next-line no-console
        else console.error(error);
      }
    };

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchData();
  }, deps);
};
