/* eslint-disable no-console */
import { Empty } from "antd";
import { useLocation } from "react-use";
import { chain, get, includes, some } from "lodash";
import React from "react";
import moment from "moment";
import { useIntl, FormattedMessage, defineMessage } from "react-intl";
import { FilterOutlined, SortAscendingOutlined } from "@ant-design/icons";
import { IPackage, IQueryConfigArgs } from "../../../App/common/typings";
import { delay, urlStringToOBject } from "../../../App/common/util/util";
import { Loader } from "../../molecules";
import { Button, Select } from "../../atoms";
import PackageItem from "./PackageItem";
const sortOptions = [
  {
    label: defineMessage({
      description: "Price Low to High",
      defaultMessage: "Price Low to High",
      id: "cVXf/g",
    }),
    value: "minPricePerAdult-asc",
  },
  {
    label: defineMessage({
      description: "Price High to Low",
      defaultMessage: "Price High to Low",
      id: "8cF34B",
    }),
    value: "minPricePerAdult-desc",
  },
  {
    label: defineMessage({
      description: "Travel Date Ascending",
      defaultMessage: "Travel Date Ascending",
      id: "jB/cqi",
    }),
    value: "departureDate-asc",
  },
  {
    label: defineMessage({
      description: "Travel Date Descending",
      defaultMessage: "Travel Date Descending",
      id: "O08693",
    }),
    value: "departureDate-desc",
  },
  {
    label: defineMessage({
      description: "Package Name A - Z",
      defaultMessage: "Package Name A - Z",
      id: "j0EK1D",
    }),
    value: "name-asc",
  },
  {
    label: defineMessage({
      description: "Package Name Z - A",
      defaultMessage: "Package Name Z - A",
      id: "OB23fp",
    }),
    value: "name-desc",
  },
];

export interface IPackageItemListProps {
  travelPackages: IPackage[];
  sorting: IQueryConfigArgs;
  isTabletOrMobile: boolean;
  isMobile: boolean;
  onShowFilter: () => void;
  onSort: (value: string) => void;
  partner?: string;
}
export const PackageItemList: React.FC<IPackageItemListProps> = ({
  travelPackages,
  sorting,
  isTabletOrMobile,
  isMobile,
  onShowFilter,
  onSort,
  partner,
}) => {
  const urlLocation = useLocation();
  const { formatMessage } = useIntl();
  const [currentPackages, setCurrentPackages] = React.useState<IPackage[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [discount] = React.useState<boolean>(false);
  React.useEffect(() => {
    setLoading(true);
    const pr = urlStringToOBject(
      get(urlLocation, "pathname", "").replace("/packages/", "")
    );
    const defaultPackages = chain(travelPackages)
      .orderBy(sorting.name, sorting.order)
      .filter((p: any) => {
        if (pr.from && pr.from !== "all") {
          return includes(pr.from.split("_"), p.departureCity?.code);
        }
        return true;
      })
      .filter((p: any) => {
        if (pr.to && pr.to !== "all") {
          return some(p.destinationCodes, (el) =>
            includes(pr.to.split("_"), el)
          );
        }
        return true;
      })
      .filter((p: any) => {
        if (pr.hotels && pr.hotels !== "all") {
          return some(p.hotels, (el) =>
            includes(pr.hotels.split("_"), el.hotelId)
          );
        }
        return true;
      })
      .filter((p: any) => {
        if (pr.when && pr.when !== "all") {
          return includes(
            pr.when.split("_"),
            moment(p.departureDate).format("MM-YYYY")
          );
        }
        return true;
      })
      .filter((p: any) => {
        if (pr.price) {
          const [min, max] = pr.price.split("_");
          if (min && max) {
            return p.minPricePerAdult >= min && p.minPricePerAdult <= max;
          }
        }
        return true;
      })
      .filter((p: any) => {
        if (pr.category && pr.category !== "all") {
          return some(p.categoryCodes, (el) =>
            includes(pr.category.split("_"), el)
          );
        }
        return true;
      })
      .value();

    setCurrentPackages(defaultPackages);

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    delay(500).then(() => setLoading(false));
  }, [sorting, travelPackages, urlLocation]);
  if (loading) return <Loader color="success" title="Loading" />;
  return (
    <div className="package-list-frontend-right">
      {discount && (
        <div className="package-list-frontend-discount-text">
          Προσφορά Μόνο για τη Black Friday! -10% σε όλα μας τα πακέτα{" "}
        </div>
      )}
      <div className="package-list-frontend-header">
        {isTabletOrMobile && (
          <div className="package-list-frontend-header-filter">
            <Button type="link" onClick={onShowFilter}>
              <FilterOutlined />
            </Button>
          </div>
        )}
        <div className="package-list-frontend-header-result">
          {" "}
          {currentPackages.length}{" "}
          <FormattedMessage
            description="Results"
            defaultMessage="Results"
            id="r1l+Iq"
          />
        </div>
        <div className="package-list-frontend-header-sorting">
          <span className="title">
            {!isMobile ? (
              <FormattedMessage
                description="Sorting"
                defaultMessage="Sorting"
                id="WwgJXV"
              />
            ) : (
              <SortAscendingOutlined />
            )}
          </span>
          <Select
            defaultValue="minPricePerAdult-asc"
            options={sortOptions.map((v) => ({
              label: formatMessage(v.label),
              value: v.value,
            }))}
            value={`${sorting.name}-${sorting.order}`}
            onChange={onSort}
          />
        </div>
      </div>
      <div className="package-list-frontend-result">
        {currentPackages.map((tavelPackage: IPackage) => (
          <PackageItem
            key={tavelPackage?.id}
            travelPackage={tavelPackage}
            isMobile={isMobile}
            partner={partner}
            discount={discount ? 0.1 : undefined}
          />
        ))}
        {currentPackages.length === 0 && (
          <Empty
            description={formatMessage({
              description: "No packages available",
              defaultMessage: "No packages available",
              id: "OIjx77",
            })}
          >
            <FormattedMessage
              description="Reset your Search"
              defaultMessage="Reset your Search"
              id="fQ5Arf"
            />
          </Empty>
        )}
      </div>
    </div>
  );
};
